/* eslint-disable no-useless-escape */
import React from "react";
import { Form, Icon as LegacyIcon } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { UserOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Modal, Input, message, Avatar } from "antd";
import { FormattedMessage, injectIntl } from "react-intl";
import screenfull from "screenfull";
import { inject, observer } from "mobx-react";
import { Link, withRouter } from "react-router-dom";
import { isAuthenticated } from "@/utils/Session";
import API from "@/api/api";
import md5 from "js-md5"
const FormItem = Form.Item;

//withRouter一定要写在前面，不然路由变化不会反映到props中去
@withRouter
@inject("appStore")
@observer
@Form.create()
class HeaderBar extends React.Component {
    state = {
        icon: "arrows-alt",
        modalVisible: false,
        loading: false,
        btnLoading: false,
    };

    componentDidMount() {
        screenfull.onchange(() => {
            this.setState({
                icon: screenfull.isFullscreen ? "shrink" : "arrows-alt",
            });
        });
    }

    componentWillUnmount() {
        screenfull.off("change");
    }

    toggle = () => {
        this.props.onToggle();
    };

    screenfullToggle = () => {
        if (screenfull.isEnabled) {
            screenfull.toggle();
        }
    };

    logout = async () => {
        let res = await API.logout();
        if (res.code === 200) {
            // 清除token
            sessionStorage.removeItem("token");
            this.props.appStore.toggleLogin(false);
            this.props.history.push("/page/login");
        }
    };

    // 保存
    handleSubmit = (e) => {
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.setState({ btnLoading: true });
                let params = {
                    login: isAuthenticated(),
                    oldp: md5(values.oldpwd),
                    newpp: md5(values.newpwd)
                };
                try {
                    await API.admin.updatePassword(params);
                    const { intl: { formatMessage } } = this.props;
                    const successTips = formatMessage({ id: "SuccessfulOperation" });
                    message.success(successTips);
                    this.setState({
                        modalVisible: false,
                    });
                    this.logout();
                } catch(e) {
                    this.setState({ btnLoading: false });
                }
            }
        });
    };

    // 打开弹框
    showModal = (e) => {
        this.setState({
            modalVisible: true
        });
    };

    /**
     * 点击取消按钮
     */
    handleCancel = (e) => {
        this.setState({
            modalVisible: false,
            btnLoading: false,
        });
        this.props.form.resetFields();
    };

    /**
     * 验证确认密码是否正确
     */
    compareToFirstPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && value !== form.getFieldValue("newpwd")) {
            callback(<FormattedMessage id="TwoPasswordDiff" />);
        } else {
            callback();
        }
    };

    render() {
        const { icon } = this.state;
        const { appStore, collapsed, location } = this.props;
        const notLogin = (
            <div>
                <Link
                    to={{ pathname: "/page/login", state: { from: location } }}
                    style={{ color: "rgba(0, 0, 0, 0.65)" }}
                >
                    登录
                </Link>
                &nbsp;
                <img src={require("../../assets/img/defaultUser.jpg")} alt="" />
            </div>
        );
        const menu = (
            <Menu className="menu">
                <Menu.ItemGroup
                    title={<FormattedMessage id="UserCenter" />}
                    className="menu-group"
                >
                    <Menu.Item>
                        <FormattedMessage id="Hello" /> - {isAuthenticated()}
                    </Menu.Item>
                    {/* 修改密码 */}
                    <Menu.Item>
                        <span onClick={() => this.showModal()}>
                            <FormattedMessage id="Modifypassword" />
                        </span>
                    </Menu.Item>
                    <Menu.Item>
                        <span onClick={this.logout}>
                            <FormattedMessage id="Logout" />
                        </span>
                    </Menu.Item>
                </Menu.ItemGroup>
            </Menu>
        );
        const login = (
            <Dropdown overlay={menu}>
                <Avatar icon={<UserOutlined />} />
            </Dropdown>
        );
        const { getFieldDecorator } = this.props.form;
        return (
            <div id="headerbar">
                <LegacyIcon
                    type={collapsed ? "menu-unfold" : "menu-fold"}
                    className="trigger"
                    onClick={this.toggle}
                    style={{ color: "#fff" }}
                />
                <div style={{ float: "right" }}>
                    <ul className="header-ul">
                        <li>
                            <LegacyIcon
                                type={icon}
                                onClick={this.screenfullToggle}
                                style={{ color: "#fff" }}
                            />
                        </li>
                        <li>{appStore.isLogin ? login : notLogin}</li>
                    </ul>
                </div>
                {/* 修改密码弹框 */}
                <Modal
                    visible={this.state.modalVisible}
                    title={<FormattedMessage id="Modifypassword" />}
                    onOk={this.handleSubmit}
                    confirmLoading={this.state.btnLoading}
                    onCancel={this.handleCancel}
                >
                    <Form layout="horizontal" ref="myForm">
                        {/* 旧密码 */}
                        <FormItem label={<FormattedMessage id="OldPassword" />} {...window.constants.formItemLayout} >
                            {getFieldDecorator("oldpwd", {
                                rules: [{
                                    required: true,
                                    whitespace: true,
                                    message: (<FormattedMessage id="InputItem" values={{ name: (<FormattedMessage id="OldPassword" />) }} />),
                                }],
                            })(<Input type="password" />)}
                        </FormItem>
                        {/* 新密码 */}
                        <FormItem label={<FormattedMessage id="NewPassword" />} {...window.constants.formItemLayout} >
                            {getFieldDecorator("newpwd", {
                                rules: [{
                                    required: true,
                                    whitespace: true,
                                    message: (<FormattedMessage id="InputItem" values={{ name: (<FormattedMessage id="NewPassword" />) }} />),
                                }, {
                                    pattern: /^[A-Z]\w{7,}$/,
                                    message: (<FormattedMessage id="passwordRule" />),
                                }],
                            })(<Input type="password" />)}
                        </FormItem>
                        {/* 确认密码 */}
                        <FormItem label={<FormattedMessage id="ConfirmPassword" />} {...window.constants.formItemLayout} >
                            {getFieldDecorator("ConfirmPassword", {
                                rules: [{
                                    required: true,
                                    whitespace: true,
                                    message: (<FormattedMessage id="PleaseConfirmPassword" />),
                                }, {
                                    validator: this.compareToFirstPassword
                                }],
                            })(<Input type="password" />)}
                        </FormItem>
                    </Form>
                </Modal>
            </div>
        );
    }
}

export default injectIntl(HeaderBar);
