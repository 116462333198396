import React from "react";
import BGParticle from "@/utils/BGParticle";
import { LockOutlined, SafetyOutlined, UserOutlined } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Input, Row, Col, notification, Checkbox, Spin } from "antd";
import "./style.css";
import Utils from "@/utils/utils";
import PromptBox from "@/components/PromptBox";
import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import Loading2 from "@/components/Loading2";
import "animate.css";
import API from "@/api/api";
import redux from "@/redux/Store";
// import Action from "@/redux/Action";
import md5 from "js-md5"

const url = require("@/assets/img/bg.png");
const imgs = [];

@withRouter @inject("appStore") @observer @Form.create()
class LoginForm extends React.Component {
	state = {
		focusItem: -1, //保存当前聚焦的input
		checked: localStorage.getItem("loginName") ? true : false,
		loadingLogin: false,
	};

	componentDidMount() {
		this.createCodeBackEnd();
		// 定时刷新验证码,3分钟刷新一次
		setInterval(this.createCodeBackEnd, 180000);
	}

	/**
	 * 生成验证码
	 */
	createCode = () => {
		const ctx = this.canvas.getContext("2d");
		const chars = [1, 2, 3, 4, 5, 6, 7, 8, 9, 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'j', 'k', 'l', 'm', 'n', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
		let code = "";
		ctx.clearRect(0, 0, 80, 39);
		for (let i = 0; i < 4; i++) {
			const char = chars[Utils.randomNum(0, 57)];
			code += char;
			ctx.font = Utils.randomNum(20, 25) + "px SimHei"; //设置字体随机大小
			ctx.fillStyle = "#D3D7F7";
			ctx.textBaseline = "middle";
			ctx.shadowOffsetX = Utils.randomNum(-3, 3);
			ctx.shadowOffsetY = Utils.randomNum(-3, 3);
			ctx.shadowBlur = Utils.randomNum(-3, 3);
			ctx.shadowColor = "rgba(0, 0, 0, 0.3)";
			let x = (80 / 5) * (i + 1);
			let y = 39 / 2;
			let deg = Utils.randomNum(-25, 25);
			/**设置旋转角度和坐标原点**/
			ctx.translate(x, y);
			ctx.rotate((deg * Math.PI) / 180);
			ctx.fillText(char, 0, 0);
			/**恢复旋转角度和坐标原点**/
			ctx.rotate((-deg * Math.PI) / 180);
			ctx.translate(-x, -y);
		}
		this.setState({
			code,
		});
	};

	createCodeBackEnd = async () => {
		this.setState({
			codeImg: this.timestamp(window.constants.baseURL + "/admin/verifyCode"),
		});
	};

	//为url添加时间戳
	timestamp = (url) => {
		var getTimestamp = new Date().getTime();
		if (url.indexOf("?") > -1) {
			url = url + "&timestamp=" + getTimestamp;
		} else {
			url = url + "?timestamp=" + getTimestamp;
		}
		return url;
	};

	// 登录
	loginSubmit = (e) => {
		e.preventDefault();
		this.setState({
			focusItem: -1,
		});
		this.props.form.validateFields(async (err, values) => {
			if (!err) {
				this.setState({
					loadingLogin: true,
				});
				// 密码加密
				values.password = md5(values.password);
				try {
					let res = await API.login(values);
					// 获取并保存token
					let token = res.content.tinfo;
					sessionStorage.setItem("token", token);
					// 获取用户基本信息
					// this.getFindAdmin();
					sessionStorage.setItem("adminInfo", JSON.stringify(res.content));
					this.props.appStore.toggleLogin(true, {
						loginName: values.login,
					});

					// 获取用户单位数据
					// redux.dispatch(await Action.getUserGroup("update"));
					const { from } = this.props.location.state || { from: { pathname: "/" } };
					this.props.history.push(from);
				} catch (e) {
					this.createCodeBackEnd();
				} finally {
					this.setState({
						loadingLogin: false,
					});
				}
			}
		});
	};

	// 获取用户基本信息
	getFindAdmin = async () => {
		let res = await API.admin.findAdmin();
		sessionStorage.setItem("adminInfo", JSON.stringify(res.content));
	};

	/**
	 * 记住账号
	 */
	RemenberLoginMsg = (checkedValue) => {
		const { getFieldValue } = this.props.form;
		this.setState({ checked: checkedValue.target.checked });
		if (checkedValue.target.checked) {
			localStorage.setItem("loginName", getFieldValue("login"));
		} else {
			localStorage.removeItem("loginName");
		}
	};

	render() {
		const { getFieldDecorator, getFieldError } = this.props.form;
		const { focusItem, checked } = this.state;
		return (
			<Spin tip="登录中..." spinning={this.state.loadingLogin}>
				<div className="container">
					<div className={this.props.className}>
						<h3 className="title">筑泰信息官网后台管理平台</h3>
						<Form onSubmit={this.loginSubmit}>
							<Form.Item
								help={
									getFieldError("login") && (<PromptBox info={getFieldError("login")} width={Utils.calculateWidth(getFieldError("login"))} />)
								}
							>
								{getFieldDecorator("login", {
									initialValue: localStorage.getItem("loginName") ? localStorage.getItem("loginName") : "",
									rules: [{ required: true, whitespace: true, message: "请输入用户名" }],
								})(
									<Input
										onFocus={() => this.setState({ focusItem: 0 })}
										onBlur={() => this.setState({ focusItem: -1 })}
										onChange={(e) => {
											if (e.target.value !== localStorage.getItem("loginName")) {
												this.setState({ checked: false });
												localStorage.removeItem("loginName");
											}
										}}
										maxLength={16}
										placeholder="用户名"
										addonBefore={
											<UserOutlined style={focusItem === 1 ? styles.focus : {}} />
										}
									/>
								)}
							</Form.Item>
							<Form.Item
								help={
									getFieldError("password") && (<PromptBox info={getFieldError("password")} width={Utils.calculateWidth(getFieldError("password"))} />)
								}
							>
								{getFieldDecorator("password", {
									initialValue: "",
									rules: [{ required: true, whitespace: true, message: "请输入密码" }],
								})(
									<Input
										onFocus={() => this.setState({ focusItem: 1 })}
										onBlur={() => this.setState({ focusItem: -1 })}
										// onChange={(e) => {
										//   if (e.target.value !== localStorage.getItem('password')) {
										//     this.setState({checked: false})
										//     localStorage.removeItem('password')
										//   }
										// }}
										type="password"
										maxLength={16}
										placeholder="密码"
										addonBefore={
											<LockOutlined style={focusItem === 1 ? styles.focus : {}} />
										}
									/>
								)}
							</Form.Item>
							<Form.Item
								help={
									getFieldError("verifyCode") && (<PromptBox info={getFieldError("verifyCode")} width={Utils.calculateWidth(getFieldError("verifyCode"))} />)
								}
							>
								{getFieldDecorator("verifyCode", {
									validateFirst: true,
									rules: [{ required: true, whitespace: true, message: "请输入验证码" }],
								})(
									<Row>
										<Col span={15}>
											<Input
												onFocus={() => this.setState({ focusItem: 2 })}
												onBlur={() => this.setState({ focusItem: -1 })}
												maxLength={4}
												placeholder="验证码"
												addonBefore={
													<SafetyOutlined style={focusItem === 2 ? styles.focus : {}} />
												}
											/>
										</Col>
										<Col span={9} style={{ height: "39px",textAlign: 'right' }}>
											{/* <canvas onClick={this.createCode} width="80" height='39' ref={el => this.canvas = el}/> */}
											<img
												alt="验证码"
												src={this.state.codeImg}
												onClick={this.createCodeBackEnd}
												style={{ cursor: "pointer" }}
											></img>
										</Col>
									</Row>
								)}
							</Form.Item>
							<div>
								<Checkbox
									checked={checked}
									className="loginCheck"
									onChange={this.RemenberLoginMsg}
								>
									记住账号
								</Checkbox>
							</div>
							<div className="bottom">
								<input className="loginBtn" type="submit" value="登录" />
							</div>
						</Form>
					</div>
				</div>
			</Spin>
		);
	}
}

@withRouter @inject("appStore") @observer
class Login extends React.Component {
	state = {
		showBox: "login", //展示当前表单
		url: "", //背景图片
		loading: false,
		loading2: false,
	};

	get background() {
		let background = redux.getState().background;
		if (!background || background === "null") {
			background = require("../../assets/img/bg.png");
		} else {
			background = window.constants.baseURL + background.replace(/\\/g, "/");
		}
		return background;
	}

	componentDidMount() {
		this.initPage();
		Utils.preloadingImages(imgs); //预加载下一个页面的图片，预加载了第二次为什么还会去请求图片资源？
	}

	componentWillUnmount() {
		this.particle.destory();
		notification.destroy();
	}

	//载入页面时的一些处理
	initPage = () => {
		this.setState({
			loading: true,
		});
		this.loadImageAsync(url).then((url) => {
			this.setState({
				loading: false,
				url,
			});
		}).then(() => {
			//为什么写在then里？id为backgroundBox的DOM元素是在loading为false时才有，而上面的setState可能是异步的，必须等到setState执行完成后才去获取dom
			this.particle = new BGParticle("backgroundBox");
			this.particle.init();
		});
	};

	//切换showbox
	switchShowBox = (box) => {
		this.setState({
			showBox: box,
		});
	};

	//登录的背景图太大，等载入完后再显示，实际上是图片预加载，
	loadImageAsync(url) {
		return new Promise(function (resolve, reject) {
			const image = new Image();
			image.onload = function () {
				resolve(url);
			};
			image.onerror = function () {
				console.log("图片载入错误");
			};
			image.src = url;
		});
	}

	render() {
		const { showBox, loading } = this.state;
		return (
			<div id="login-page">
				{loading ? (
					<div>
						<h3 style={styles.loadingTitle} className="animated bounceInLeft">载入中...</h3>
						<Loading2 />
					</div>
				) : (
					<div>
						<div id="backgroundBox" style={{ ...styles.backgroundBox, backgroundImage: `url(${this.background})` }} />
						<LoginForm
							className={showBox === "login" ? "box showBox" : "box hiddenBox"}
							switchShowBox={this.switchShowBox}
						/>
					</div>
				)}
			</div>
		);
	}
}

const styles = {
	backgroundBox: {
		position: "fixed",
		top: "0",
		left: "0",
		width: "100vw",
		height: "100vh",
		backgroundSize: "100% 100%",
		transition: "all .5s",
	},
	focus: {
		width: "20px",
		opacity: 1,
	},
	loadingBox: {
		position: "fixed",
		top: "50%",
		left: "50%",
		transform: "translate(-50%,-50%)",
	},
	loadingTitle: {
		position: "fixed",
		top: "50%",
		left: "50%",
		marginLeft: -45,
		marginTop: -18,
		color: "#000",
		fontWeight: 500,
		fontSize: 24,
	},
};

export default Login;
