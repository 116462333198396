import React from "react"
import { message } from 'antd'
import { FormattedMessage } from 'react-intl';

function accMul(arg1, arg2) {
    let m = 0;
    const s1 = arg1.toString();
    const s2 = arg2.toString();
    m += s1.split(".").length > 1 ? s1.split(".")[1].length : 0;
    m += s2.split(".").length > 1 ? s2.split(".")[1].length : 0;
    return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / 10 ** m;
}

/**
 * 清空输入框数据
 */
function emitEmpty(name,_this) {
    _this[name].focus();
    _this.props.form.setFieldsValue({[name]: ''})
}

export default {
	digitUppercase(n) {
		const fraction = ['角', '分'];
		const digit = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
		const unit = [['元', '万', '亿'], ['', '拾', '佰', '仟', '万']];
		let num = Math.abs(n);
		let s = '';
		fraction.forEach((item, index) => {
			s += (digit[Math.floor(accMul(num, 10 * 10 ** index)) % 10] + item).replace(/零./, '');
		});
		s = s || '整';
		num = Math.floor(num);
		for (let i = 0; i < unit[0].length && num > 0; i += 1) {
			let p = '';
			for (let j = 0; j < unit[1].length && num > 0; j += 1) {
				p = digit[num % 10] + unit[1][j] + p;
				num = Math.floor(num / 10);
			}
			s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s;
		}

		return s
			.replace(/(零.)*零元/, '元')
			.replace(/(零.)+/g, '零')
			.replace(/^整$/, '零元整');
	},

	/**
	 * 生成指定区间的随机整数
	 * @param min
	 * @param max
	 * @returns {number}
	 */
	randomNum(min, max) {
		return Math.floor(Math.random() * (max - min) + min);
	},

	/**
	 * 计算提示框的宽度
	 * @param str
	 * @returns {number}
	 */
	calculateWidth(arr) {
		return 30 + arr[0].length * 15
	},

	/**
	 * 图片预加载
	 * @param arr
	 * @constructor
	 */
	preloadingImages(arr) {
		arr.forEach(item => {
			const img = new Image()
			img.src = item
		})
	},

	/**
	 * @visibleType:String  显示弹框的类型
	 * @mustSelected:Int 是否必须选择一条数据或多条数据
	 */
	showModal(_this, visibleType, mustSelected) {
		if(mustSelected) {
			if(mustSelected === 1 && _this.state.selectedRowKey) {
				_this.setState({
					[visibleType]: true
				})
			} else if(mustSelected === 2 && _this.state.selectedRowKeys.length) {
				_this.setState({
					[visibleType]: true
				})
			} else {
				// 请选择一行
				const { intl: { formatMessage } } = _this.props
				const PleaseCheck = formatMessage({id:'PleaseCheck'});
				message.destroy();
				message.warning(PleaseCheck);
			}
		} else {
			_this.setState({
				[visibleType]: true
			})
		}
	},

	/**
	 * Modal 弹窗组件回调方法，关闭弹窗
	 */
	hideModal(_this, params) {
		_this.setState({
			[params.visibleType]: false
		})

		// 刷新数据
		if(params.dataRefresh) {
			// 延迟刷新
			setTimeout(() => {
				if(params.visibleType === "deleteVisible") {
					_this.setState({
						selectedRowKey: "",
						selectedRowKeys: [],
						pageNo: 0
					}, () => {
						_this.getRemoteData()
					})
				} else {
					_this.getRemoteData();
				}
			}, 1000);
		}
	},

	/**
	 * 过滤数据中的空childs
	 */
	filterEmptyChilds(data) {
		if(data) {
			// 遍历数组
			data.forEach(item => {
				// 遍历对象
				for(let key in item) {
					if(key === 'childs') {
						if(item[key] && !item[key].length) {
							delete item[key];
						} else {
							this.filterEmptyChilds(item[key]);
						}
					}
				}
			})
		}
		return data;
	},

  	// 根据ID匹配返回树形结构数据的对象
	filterSelectedRow(id, data) {
		for(let i = 0; i < data.length; i++) {
			if(data[i].id === id) {
				return data[i];
			} else {
				if(data[i].childs && data[i].childs.length) {
					let selectRow = this.filterSelectedRow(id, data[i].childs);
					if(selectRow) {
						return selectRow;
					}
				}
			}
		}
	},

	/**
	 * 表格 分页、排序、筛选变化时触发
	 */
	onTableChange(_this, pagination, filters, sorter, extra) {
		// 当排序发生变化时
		if(sorter && Object.keys(sorter).length > 0){
			_this.setState({
				sortField: sorter.field,
				sortOrder: sorter.order ? sorter.order.replace("end","") : sorter.order
			}, () => {
				_this.getRemoteData()
			})
		}
	},

	/**
	 * 分页改变
	 */
	onPageChange(_this, page, pageSize) {
		_this.setState({
			pageNo: page - 1,
			pageSize: pageSize
		}, () => {
			// 重新加载数据
			_this.getRemoteData();
		})
	},

	/**
	 * 统计时间 XX天XX小时XX分钟
	 * @param {*} time
	 */
	formatTimeAccount(time) {
		var days    = Math.floor(time / 1000 / 60 / 60 / 24);
		var hours   = Math.floor(time/ 1000 / 60 / 60 - (24 * days));
		var minutes = Math.floor(time / 1000 /60 - (24 * 60 * days) - (60 * hours));
		if(days === 0) {
			if(hours === 0) {
				return (<FormattedMessage id="MinutesAccount" values={{minutes}} />)
			} else {
				return (<FormattedMessage id="HoursAccount" values={{hours, minutes}} />)
			}
		} else {
			return (<FormattedMessage id="DayAccount" values={{days, hours, minutes}} />)
		}
	},

	formatDateAccount(time) {
		var days    = Math.floor(time / 1000 / 60 / 60 / 24);
		var hours   = Math.floor(time/ 1000 / 60 / 60);
		var minutes = Math.floor(time / 1000 /60);
		if(days === 0) {
			if(hours === 0) {
				return (<FormattedMessage id="MinutesAgo" values={{n: minutes}} />)
			} else {
				return (<FormattedMessage id="HoursAgo" values={{n: hours}} />)
			}
		} else {
			return (<FormattedMessage id="DaysAgo" values={{n: days}} />)
		}
	},

	formatAppSize(appSize) {
		if(appSize < 1024) {
			return appSize + " BYTES"
		} else if(appSize < 1024*1024) {
			return (appSize/1024).toFixed(2) + " KB"
		} else if(appSize < 1024*1024*1024) {
			return (appSize/1024/1024).toFixed(2) + " M"
		} else if(appSize < 1024*1024*1024*1024) {
			return (appSize/1024/1024/1024).toFixed(2) + " G"
		}
	},

	/**
	 * 增加一行应用输入框
	 */
	addAppInput(_this, key) {
		let appList = _this.state[key]
		appList.push({
			appName: '',
			appPackage: ''
		})
		_this.setState({ [key]: appList })
	},

	/**
	 * 删除一行应用输入框
	 */
	removeAppInput(_this, index, key) {
		const { form } = _this.props;
		let appList = form.getFieldValue(key);
		appList.splice(index, 1);
		if (appList.length === 0) {
			appList.push({
				appName: '',
				appPackage: ''
			})
		}
		form.setFieldsValue({ [key]: appList })
		_this.setState({ [key]: appList })
	},

	/*
	* 控制输入框清除按钮是否显示
	*/
	suffix(_this,name,Icon) {
		const visible = _this.props.form.getFieldValue(name) ? {visibility: 'visible'} : {visibility: 'hidden'}
		return <Icon type="close-circle" onClick={() => emitEmpty(name,_this)} style={{ ...visible, color: 'rgba(0,0,0,.25)'}}/>
	},

}
