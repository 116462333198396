import React from 'react'
import { withRouter, Switch, Redirect } from 'react-router-dom'
import LoadableComponent from '../../utils/LoadableComponent'
import PrivateRoute from '../PrivateRoute'

const Home = LoadableComponent(()=>import('../../routes/Home/index'))  //参数一定要是函数，否则不会懒加载，只会代码拆分

// 公司信息
const Info = LoadableComponent(() => import("../../routes/Info/index"))

// 新闻列表
const News = LoadableComponent(() => import("../../routes/News/index"))

// 职位列表
const Position = LoadableComponent(() => import("../../routes/Position/index"))

// 来访信息列表
const Visitor = LoadableComponent(() => import("../../routes/Visitor/index"))

// 账号管理
const Admin = LoadableComponent(() => import("../../routes/Admin/index"))

@withRouter
class ContentMain extends React.Component {
	render () {
		return (
			<div style={{padding: 16, position: 'relative', minWidth: 1000}}>
				<Switch>
					<PrivateRoute exact path='/page/home' component={Home}/>
					
					<PrivateRoute exact path='/page/info/index' component={Info}/>
					<PrivateRoute exact path='/page/news/index' component={News}/>
					<PrivateRoute exact path='/page/position/index' component={Position}/>
					<PrivateRoute exact path='/page/visitor/index' component={Visitor}/>
					<PrivateRoute exact path='/page/admin/index' component={Admin}/>

					<Redirect exact from='/' to='/page/info/index'/>
				</Switch>
			</div>
		)
	}
}

export default ContentMain