import qs from "qs";
import axios from "axios";
import { message } from "antd";
import appStore from "../store/appStore";
import { createHashHistory } from 'history'

const booleanToInt = (obj) => {
	if (!obj || typeof obj !== "object") {
		return obj;
	}
	Object.keys(obj).forEach((key) => {
		// 将true和false 转换成1和0
		if (obj[key] === "true" || obj[key] === true) {
			obj[key] = 1;
		} else if (obj[key] === "false" || obj[key] === false) {
			obj[key] = 0;
		} else if (typeof obj[key] === "object") {
			obj[key] = booleanToInt(obj[key]);
		}
	});
	return obj;
};

const toTrim = (obj) => {
	if (!obj || typeof obj !== "object") {
		return obj;
	}
	Object.keys(obj).forEach((key) => {
		// 去字符串前后空格
		if (typeof obj[key] === "string") {
			obj[key] = obj[key].replace(/(^\s*)|(\s*$)/g, "");
		} else if (typeof obj[key] === "object") {
			obj[key] = toTrim(obj[key]);
		}
	});
	return obj;
};

// 请求拦截
axios.interceptors.request.use((config) => {
	// 从cookie中获取token
	let token = sessionStorage.getItem("token");
	config.headers.token = token;
	if (config.headers["Content-Type"] !== "multipart/form-data") {
		let data = qs.parse(config.data);
		// 将true和false 转换成1和0
		data = booleanToInt(data);
		// 去前后空格
		data = toTrim(data);
		config.data = data;
	}
	return config;
}, (err) => {
	return Promise.reject(err);
});

// 拦截响应response，并做一些错误处理
axios.interceptors.response.use((response) => {
	return response;
}, (err) => {
	// 这里是返回状态码不为200时候的错误处理
	if (err && err.response) {
		message.error(err.message);
	}
	return Promise.reject(err);
});

export default {
	baseURL: (() => {
		// 判断是在生产环境还是开发环境，如果是生产环境baseURL为/api
		if (process.env.NODE_ENV === "development") {
			return "/api";
		} else {
			return "http://120.78.162.185:18010";
		}
	})(),
	ajaxs(getUrl, params, methods) {
		return new Promise((resolve, reject) => {
			let data = Object.assign({}, params);
			let axiosParams = {
				url: this.baseURL + getUrl,
				method: methods ? methods : "POST",
				timeout: 1000 * 60 * 5,
				headers: {
					"Content-Type": "application/json",
				},
				withCredentials: true
			};
			//添加参数
			if (methods === "GET") {
				Object.defineProperty(axiosParams, "params", {
					value: data,
					enumerable: true,
				});
			} else {
				Object.defineProperty(axiosParams, "data", {
					value: data,
					enumerable: true,
				});
			}
			axios(axiosParams).then((res) => {
				if (res.data.code === 200) {
					resolve(res.data);
				} else if (res.data.code === 301 || res.data.code === 400) {
					// 退出登录状态   301-超时，请重新登陆   400-需登陆   401系统错误
					appStore.toggleLogin(false);
					// window.location = "/page/login";
					createHashHistory().push('/page/login')
					reject(res.data);
				} else {
					message.error(res.data.msg);
					reject(res.data);
				}
			}, (err) => {
				reject(err);
			}).catch((err) => {
				reject(new Error(err));
			});
		});
	},
	//图片上传
	uploadFile(getUrl, params, progress) {
		return new Promise((resolve, reject) => {
			let config = {
				url: this.baseURL + getUrl,
				data: params,
				method: "post",
				headers: {
					"Content-Type": "multipart/form-data",
				},
			};
			// 上传进度
			if (progress) {
				config.onUploadProgress = progress;
			}
			axios(config).then((res) => {
				if (res.data.code === 200) {
					resolve(res.data);
				} else {
					message.error(res.data.msg);
					reject(res.data);
				}
			}, (err) => {
				reject(err);
			}).catch((err) => {
				throw new Error(err);
			});
		});
	},
};
