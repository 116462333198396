import React from 'react'
import {Layout} from 'antd'
import SiderNav from '../../components/SiderNav'
import ContentMain from '../../components/ContentMain'
import HeaderBar from '../../components/HeaderBar'

const {Sider, Header, Content} = Layout

class Index extends React.Component{
	state = {
		collapsed: false
	}

	toggle = () => {
		this.setState({
		collapsed: !this.state.collapsed
		})
	}

	render() {
		// 设置Sider的minHeight可以使左右自适应对齐
		return (
			<div id='page'>
				<Layout>
					<Sider collapsible trigger={null} collapsed={this.state.collapsed} style={{ zIndex: 99,minHeight: '100vh' }}>
						<SiderNav collapsed={this.state.collapsed}/>
					</Sider>
					<Layout>
						<Header style={{padding: '0 16px'}}>
							<HeaderBar collapsed={this.state.collapsed} onToggle={this.toggle}/>
						</Header>
						<Content style={{overflow:"auto"}}>
							<ContentMain/>
						</Content>
					</Layout>
				</Layout>
			</div>
		);
	}
}
export default Index