/* eslint-disable no-useless-escape */
// 多个地方会使用到的全局变量
window.constants = {
    baseURL: process.env.NODE_ENV === "development" ? "/api" : "http://120.78.162.185:18010",
    mobilePhoneReg: /^1[3-9]\d{9}$/,   //手机号码
    emailReg: /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/,   //电子邮箱
    idCardReg: /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/,   //身份证
    formItemLayout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 19 }
    },
    menus: [{
        menuName: "公司信息",
        iconName: "home",
        requestUrl: "/page/info/index",
        isMenu: 1,
        id: 1
    }, {
        menuName: "新闻管理",
        iconName: "container",
        requestUrl: "/page/news/index",
        isMenu: 1,
        id: 2
    }, {
        menuName: "职位管理",
        iconName: "idcard",
        requestUrl: "/page/position/index",
        isMenu: 1,
        id: 3
    }, {
        menuName: "来访信息",
        iconName: "mail",
        requestUrl: "/page/visitor/index",
        isMenu: 1,
        id: 4
    }, {
        menuName: "账号管理",
        iconName: "user",
        requestUrl: "/page/admin/index",
        isMenu: 1,
        id: 5
    }]
}
