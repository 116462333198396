const zh_CN = {
    Hello: '你好',
    Add: '添加',
    Update: '修改',
    Delete: '删除',
    Yes: '是',
    No: '否',
    OK: '确定',
    Cancel: '取消',
    Search: '搜索',
    All: '全部',
    Save: '保存',
    See: '查看',
    Reset: '重置',
    Prompt: '提示',
    Other: '其他',
    HomePage: '首页',
    NoResult: '暂无数据',
    UserCenter: '用户中心',
    AccountInfo: '账号资料',
    Modifypassword: '修改密码',
    Logout: '退出登录',
    ChangeBasicInformation: "修改基本信息",
    Account: '账号',
    Mailbox: "邮箱",
    PhoneNumber: '电话号码',
    AdministratorsName: '管理员姓名',
    InputItem: "请输入{name}",
    PleaseSelect: "请选择{name}",
    NewPassword: '新密码',
    OldPassword: '旧密码',
    ConfirmPassword: "确认密码",
    PleaseConfirmPassword: "请确认密码",
    TwoPasswordDiff: "两次输入的密码不一致",
    passwordRule: '请设置首字母大写，必须字母+数字，至少8位数',
    password6Bit: '密码必须是6位字母或数字',
    InputCorrect: "请输入正确的{name}",
    SureDelete: "删除后数据将不可恢复，您确定要执行此操作？",
    SuccessfulOperation: '操作成功',
}
export default zh_CN;