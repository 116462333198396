const en_US = {
    Hello: 'Hello', //你好
    Add: 'Add',  // 添加
    Update: 'Update', // 修改
    Delete: 'Delete', //删除
    Yes: 'Yes', //是
    No: 'No', //否
    OK: 'OK', // 确定
    Cancel: 'Cancel', //取消
    Search: 'Search', // 搜索
    All: 'All', // 全部
    Save: 'Save', // 保存
    See: 'See', //查看
    Reset: 'Reset', // 重置
    Prompt: 'Prompt', //提示
    Other: 'Other', //其他
    HomePage: 'Home page', //首页
    NoResult: 'No result', //暂无数据
    UserCenter: 'User center', //用户中心
    AccountInfo: 'Account Info', //账号资料
    Modifypassword: 'Modify password', //修改密码
    Logout: 'Logout', //退出登录
    ChangeBasicInformation: "Change basic information", // 修改基本信息
    Account: 'Account', //账号
    Mailbox: "Email", //邮箱
    PhoneNumber: 'Phone number', // 电话号码
    AdministratorsName: "Administrator's name", // 管理员姓名
    InputItem: "Please input the {name}.", // 请输入{name}
    PleaseSelect: "Please select {name}.", // 请选择{name}
    NewPassword: 'New Password', // 新密码
    OldPassword: 'Old Password', // 旧密码
    ConfirmPassword: "Confirm password", //确认密码
    PleaseConfirmPassword: "Please confirm password", //请确认密码
    TwoPasswordDiff: "Two passwords that you enter is inconsistent!", //两次输入的密码不一致
    passwordRule: 'Please set uppercase letters, letters + numbers, at least 8 digits.', // 请设置首字母大写，必须字母+数字，至少8位数
    password6Bit: 'The password must be 6 bit letters or numbers', //密码必须是6位字母或数字
    InputCorrect: "Please enter the correct {name}", //请输入正确的{name}
}
export default en_US;