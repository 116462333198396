import Ajax from "./Fecth";

export default {
	login(params) {
		// 登录
		return Ajax.ajaxs("/admin/login", params);
	},
	logout() {
		// 退出登录
		return Ajax.ajaxs("/admin/logout");
	},
	admin: {
		list(params) {
			// 获取管理员列表
			return Ajax.ajaxs("/admin/list", params);
		},
		add(params) {
			// 新增管理员
			return Ajax.ajaxs("/admin/add", params);
		},
		update(params) {
			//  修改管理员信息
			return Ajax.ajaxs("/admin/editManage", params);
		},
		delete(params) {
			//  删除管理员信息
			return Ajax.ajaxs("/admin/offInfo", params);
		},
		updatePassword(params) {
			//  修改管理员密码
			return Ajax.ajaxs("/admin/updatep", params);
		},
		existName(params) {
			// 判断管理员账号是否已存在
			return Ajax.ajaxs("/admin/existName", params);
		},
		xsend(params) {
			// 通用上传文件接口
			return Ajax.uploadFile("/admin/xsend", params);
		},
	},
	home: {
		listVisitor(params) {
			// 查看来访信息列表
			return Ajax.ajaxs("/home/listVisitor", params);
		},

		listRecruitInfo(params) {
			// 查看职位列表
			return Ajax.ajaxs("/home/listRecruitInfo", params);
		},
		saveRecruitInfo(params) {
			// 发布招聘信息
			return Ajax.ajaxs("/home/saveRecruitInfo", params);
		},
		editRecruitInfo(params) {
			// 修改招聘信息
			return Ajax.ajaxs("/home/editRecruitInfo", params);
		},
		offRecruitInfo(params) {
			// 关闭招聘信息
			return Ajax.ajaxs("/home/offRecruitInfo", params);
		},
		
		listNews(params) {
			// 查看新闻列表
			return Ajax.ajaxs("/home/listNews", params);
		},
		saveNews(params) {
			// 发布新闻接口
			return Ajax.uploadFile("/home/saveNews", params);
		},
		editNews(params) {
			// 修改新闻
			return Ajax.uploadFile("/home/editNews", params);
		},
		offNews(params) {
			// 关闭新闻
			return Ajax.ajaxs("/home/offNews", params);
		},
		
		listCompany(params) {
			// 查看公司信息
			return Ajax.ajaxs("/home/listCompany", params);
		},
		editCompany(params) {
			// 修改公司信息
			return Ajax.ajaxs("/home/editCompany", params);
		},
	},
};
